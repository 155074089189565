import React,{useState, useEffect} from 'react'
import { Link} from "gatsby"
import MainView from "../components/MainView/MainView"
import Layout from "../components/layout"
import { useMediaQuery } from 'react-responsive';
import useWindowSize from '../hooks/useGatsbyWindowSize'



const IndexPage = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();

  //console.log( 'mobile :' + isMobile );



useEffect(() => {

  //console.log(mobile);
  setIsLoaded(true);


},[mobile]);



if( mobile && isLoaded ){
return (

  <Layout>
    {/*<MainView items={1} mobile={true} width={width} height={height} location={props.location} />*/}
    <MainView dataSet={1} classList={'full'}prodPerView={'auto'} perView={2} mobile={true} width={width} bg={'dark'} curveTop={0} curveHeight={60} height={height} location={props.location}  />
    <div id="galleries-link">
    <Link to="/decorative-art" >DECORATIVE ART</Link>&nbsp;<Link className="selected" to="/events" >EVENTS</Link>
    </div>
  </Layout>

);
}
if( !mobile && isLoaded){
  return (
    <Layout>
      <MainView dataSet={1} classList={'full'} prodPerView={'auto'} perView={3} mobile={false} width={width} bg={'dark'} curveTop={-200} curveHeight={60} height={height} location={props.location}  />
      <div id="galleries-link">
      <Link to="/decorative-art" >DECORATIVE ART</Link>&nbsp;<Link className="selected" to="/events" >EVENTS</Link>
      </div>
    </Layout>

  );
}
}


export default IndexPage
